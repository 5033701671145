<link *ngIf="useBrowserLocale" rel="resource" type="application/l10n" [attr.xhref]="localeFolderPath+'/locale.properties'" origin="ngx-extended-pdf-viewer" />
<pdf-dark-theme *ngIf="theme==='dark'"></pdf-dark-theme>
<pdf-light-theme *ngIf="theme==='light'"></pdf-light-theme>

<pdf-dynamic-css [zoom]="mobileFriendlyZoomScale" [width]="toolbarWidthInPixels"></pdf-dynamic-css>
<div class="zoom" [style.height]="minHeight ? minHeight : height" #root>
  <div class="html">
    <div class="loadingInProgress body" [style.backgroundColor]="backgroundColor">
      <div id="outerContainer" (window:resize)="onResize()">
        <div class="free-floating-bar">
          <ng-content *ngTemplateOutlet="customFreeFloatingBar ? customFreeFloatingBar : defaultFreeFloatingBar"></ng-content>
        </div>
        <pdf-sidebar #pdfsidebar
                     [sidebarPositionTop]="sidebarPositionTop"
                     [sidebarVisible]="sidebarVisible"
                     [showSidebarButton]="showSidebarButton"
                     [customSidebar]="customSidebar"
                     [customThumbnail]="customThumbnail"
                     (thumbnailDrawn)="thumbnailDrawn.emit($event)">
        </pdf-sidebar>
        <div id="mainContainer">
          <pdf-dummy-components></pdf-dummy-components>

          <pdf-toolbar
            [customToolbar]="customToolbar"
            [mobileFriendlyZoomScale]="mobileFriendlyZoomScale"
            [primaryMenuVisible]="primaryMenuVisible"
            [showBookmarkButton]="showBookmarkButton"
            [showDownloadButton]="showDownloadButton"
            [showFindButton]="showFindButton"
            [showHandToolButton]="showHandToolButton"
            [showOpenFileButton]="showOpenFileButton"
            [showPrintButton]="showPrintButton && enablePrint"
            [showPagingButtons]="showPagingButtons"
            [showPresentationModeButton]="showPresentationModeButton"
            [showRotateButton]="showRotateButton"
            [showSecondaryToolbarButton]="showSecondaryToolbarButton && !hideKebabMenuForSecondaryToolbar"
            [showSidebarButton]="showSidebarButton"
            [showZoomButtons]="showZoomButtons"
            [textLayer]="textLayer"
            [toolbarMarginTop]="toolbarMarginTop"
            [toolbarWidth]="toolbarWidth"
            [zoomLevels]="zoomLevels"
          ></pdf-toolbar>

          <pdf-secondary-toolbar #pdfSecondaryToolbarComponent
            [customSecondaryToolbar]="customSecondaryToolbar"
            [secondaryToolbarTop]="secondaryToolbarTop"
            [mobileFriendlyZoomScale]="mobileFriendlyZoomScale"
            [showPresentationModeButton]="showPresentationModeButton"
            [showOpenFileButton]="showOpenFileButton"
            [showPrintButton]="showPrintButton && enablePrint"
            [showDownloadButton]="showDownloadButton"
            [showBookmarkButton]="showBookmarkButton"
            [showPagingButtons]="showPagingButtons"
            [showRotateButton]="showRotateButton"
            [showHandToolButton]="showHandToolButton"
            [showScrollingButton]="showScrollingButton"
            [showSpreadButton]="showSpreadButton"
            [showPropertiesButton]="showPropertiesButton"
            (spreadChange)="onSpreadChange($event)"
            (secondaryMenuIsEmpty)="onSecondaryMenuIsEmpty($event)"
          >
          </pdf-secondary-toolbar>

          <pdf-findbar
            [findbarLeft]="findbarLeft"
            [findbarTop]="findbarTop"
            [mobileFriendlyZoomScale]="mobileFriendlyZoomScale"
            [showFindButton]="showFindButton"
            [customFindbarInputArea]="customFindbarInputArea"
            [customFindbarButtons]="customFindbarButtons"
          >
          </pdf-findbar>

          <pdf-context-menu></pdf-context-menu>

          <div id="viewerContainer" [style.top]="viewerPositionTop" [style.backgroundColor]="backgroundColor">
            <div class="unverified-signature-warning" *ngIf="hasSignature && showUnverifiedSignatures">
              {{ 'unverified_signature_warning'
                  | translate: "This PDF file contains a digital signature. The PDF viewer can't verify if the signature is valid. Please download the file and open it in Acrobat Reader to verify the signature is valid."
                  | async}}

            </div>
            <div id="viewer" class="pdfViewer" (dblclick)="zoomToPageWidth($event)">
            </div>
          </div>

          <div id="errorWrapper" hidden="true">
            <div id="errorMessageLeft">
              <span id="errorMessage"></span>
              <button type="button" id="errorShowMore" data-l10n-id="error_more_info">
                More Information
              </button>
              <button type="button" id="errorShowLess" data-l10n-id="error_less_info" hidden="true">
                Less Information
              </button>
            </div>
            <div id="errorMessageRight">
              <button type="button" id="errorClose" data-l10n-id="error_close">Close</button>
            </div>
            <div class="clearBoth"></div>
            <textarea id="errorMoreInfo" hidden="true" readonly="readonly"></textarea>
          </div>
        </div>
        <!-- mainContainer -->

        <div id="overlayContainer" class="hidden">
          <div id="passwordOverlay" class="container hidden">
            <div class="dialog">
              <div class="row">
                <p id="passwordText" data-l10n-id="password_label">
                  Enter the password to open this PDF file:
                </p>
              </div>
              <div class="row">
                <input id="password" class="toolbarField" />
              </div>
              <div class="buttonRow">
                <button type="button" id="passwordCancel" class="overlayButton">
                  <span data-l10n-id="password_cancel">Cancel</span>
                </button>
                <button type="button" id="passwordSubmit" class="overlayButton">
                  <span data-l10n-id="password_ok">OK</span>
                </button>
              </div>
            </div>
          </div>
          <pdf-document-properties-overlay class="container hidden" id="documentPropertiesOverlay"> </pdf-document-properties-overlay>

          <div id="printServiceOverlay" class="container hidden">
            <div class="dialog">
              <div class="row">
                <span data-l10n-id="print_progress_message">Preparing document for printing…</span>
              </div>
              <div class="row">
                <progress value="0" max="100"></progress>
                <span data-l10n-id="print_progress_percent" data-l10n-args='{ "progress": 0 }' class="relative-progress">0%</span>
              </div>
              <div class="buttonRow">
                <button type="button" id="printCancel" class="overlayButton">
                  <span data-l10n-id="print_progress_close">Cancel</span>
                </button>
              </div>
            </div>
          </div>
         </div>
        <!-- overlayContainer -->
      </div>
      <!-- outerContainer -->
      <div id="printContainer"></div>
    </div>
  </div>
</div>

<ng-template #defaultFreeFloatingBar>

</ng-template>
