<ng-container [ngTemplateOutlet]="customFindbar ? customFindbar : defaultFindbar">
</ng-container>

<ng-template #defaultFindbar>
  <div
    class="findbar hidden doorHanger"
    [class.invisible]="!showFindButton"
    id="findbar"
    [style.transform]="'scale(' + mobileFriendlyZoomScale + ')'"
    [style.transformOrigin]="'left center'"
    [style.left]="findbarLeft"
    [style.top]="findbarTop"
  >
    <ng-container [ngTemplateOutlet]="customFindbarButtons ? customFindbarButtons : defaultFindbarButtons"> </ng-container>
    </div>
</ng-template>

<ng-template #defaultFindbarButtons>
  <pdf-find-input-area [customFindbarInputArea]="customFindbarInputArea"></pdf-find-input-area>
  <pdf-findbar-options-one-container></pdf-findbar-options-one-container>
  <pdf-findbar-options-two-container></pdf-findbar-options-two-container>
  <pdf-findbar-options-three-container></pdf-findbar-options-three-container>
  <pdf-findbar-message-container></pdf-findbar-message-container>
</ng-template>
