
  <ins #ins class="adsbygoogle {{ className }}"
    [style.display]="display"
    [style.width.px]="width"
    [style.height.px]="height"
    [attr.data-ad-client]="adClient"
    [attr.data-ad-slot]="adSlot"
    [attr.data-ad-format]="adFormat"
    [attr.data-ad-region]="adRegion"
    [attr.data-layout]="layout"
    [attr.data-adtest]="adtest"
    [attr.data-layout-key]="layoutKey"
    [attr.data-full-width-responsive]="fullWidthResponsive">
  </ins>
  